<template>
    <section>
        <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div class="content-wrapper">
            <h2 class="text-center">Offrez une carte cadeau!</h2>
            <img src="@/assets/images/import/carte-cadeaux.jpg" style="width:100%" alt="">
            <div class="row mt-5">
                 <!-- bouton image prestations -->
                 <div class="col-md-12 text-center">
                     <showServicesVue />
                 </div>
                <div class="col-md-12 mt-5">
                    <h3>Selectionnez le montant</h3>
                    <b>Sélectionner un montant correspondant à la prestation de votre choix </b>
                    <!-- <p>Carte cadeau valable 6 mois</p> -->
                    <div class="row mt-2">
                        <div class="col-md-5 col-sm-12">
                        <b-form-group
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-md="6"
                        label="Montant"
                        >
                            <b-input-group append="€">
                                <b-form-input v-model="amount" type="number" step="0.50" placeholder="Montant" min="1" max="300"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                        </div>
                        <div class="col-md-7 col-sm-12">
                            <h4>Carte cadeau valable 6 mois</h4>
                            <!-- <h4>dont TVA de 20%: {{ tva }} €</h4> -->
                        </div>
                    </div>
                </div>
               

                <div class="col-md-12 mt-4">
                    <h3>Recevez la carte par E-mail</h3>
                    <form @submit.prevent="handleSubmit">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>A l'attention de</h4>
                                <!-- new form -->
                                <b-form-group label="Nom" label-for="forName">
                                    <b-form-input
                                        id="firstname"
                                        name="firstname"
                                        v-model="firstname"
                                        type="text"
                                        placeholder="Nom" size="lg"
                                        :state="$v.firstname.$dirty ? !$v.firstname.$error : null"
                                        aria-describedby="firstname"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="firstname">
                                        Ce champ est obligatoire.
                                    </b-form-invalid-feedback>
                                </b-form-group>

                                <b-form-group label="Prénoms" label-for="forName">
                                    <b-form-input v-model="lastname"
                                        type="text" id="lastname" name="lastname"
                                        placeholder="Prénom" size="lg" 
                                        :state="$v.lastname.$dirty ? !$v.lastname.$error : null"
                                        aria-describedby="lastname"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="lastname">
                                        Ce champ est obligatoire.
                                    </b-form-invalid-feedback>
                                </b-form-group>

                                <b-form-group label="email" label-for="forName">
                                    <b-form-input v-model="email"
                                        type="email" id="email" name="email"
                                        placeholder="Email" size="lg"
                                        :state="$v.email.$dirty ? !$v.email.$error : null"
                                        aria-describedby="email"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="email">
                                        Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                </b-form-group>

                                <b-form-group label="Téléphone" label-for="tel">
                                    <b-form-input v-model="tel"
                                        type="text" id="tel" name="tel"
                                        placeholder="Téléphone" size="lg"
                                        :state="$v.tel.$dirty ? !$v.tel.$error : null"
                                        aria-describedby="tel"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="tel">
                                        Ce champ est obligatoire.
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>

                                <!-- De la part de -->
                            <div class="col-md-6 col-sm-12">
                                    <h4>De la part de</h4>
                                <b-form-group label="De la part de" label-for="toName">
                                    <b-form-input v-model="toName"
                                        type="text" id="toName" name="toName"
                                        placeholder="De la part de" size="lg"
                                        :state="$v.toName.$dirty ? !$v.toName.$error : null"
                                        aria-describedby="toName"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="toName">
                                        Ce champ est obligatoire.
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group label="Message" label-for="message">
                                    <b-form-textarea v-model="message"
                                    rows="7"
                                        type="text" id="message" name="message"
                                        placeholder="Message" size="lg"
                                        :state="$v.message.$dirty ? !$v.message.$error : null"
                                        aria-describedby="message"
                                        ></b-form-textarea>
                                        <b-form-invalid-feedback id="message">
                                            Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- honeyPot anti spam -->
                        <b-form-group
                            class="phone"
                            id="fieldset-horizontal"
                            label-cols-sm="4"
                            label-cols-lg="3"
                            content-cols-sm
                            content-cols-lg="7"
                            label="phone"
                            label-for="phone"
                            description="Veuillez entrer votre numéro téléphone"
                            rows="3"
                            max-rows="6"
                            >
                            <b-form-input v-model="phone" id="phone" ></b-form-input>
                        </b-form-group>
                        <div class="col-md-12 d-flex justify-content-center">
                            <button class="btn btn-gradient-primary">
                                Envoyer la carte cadeau
                            </button>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import showServicesVue from '../components/functions/show-services.vue'
import pageLoader from '../components/pageLoader.vue'
import { validationMixin } from 'vuelidate'
import { required, email} from 'vuelidate/lib/validators'
export default {
    name: "gift-card",
    mixins: [validationMixin],
    components: {
        showServicesVue,
        pageLoader
    },
    data(){
        return {
            ulid_user:'',
            amount:15,
            firstname:'',
            lastname:'',
            email:'',
            tel:'',
            toName:'',
            message:'',
            phone:"",
            ulidVoucher:"",
            loadershow:false
        }
    },
    computed:{
        tva(){
            return (this.amount/1.2*0.2).toFixed(2)
        },
        gift_value(){
            return (this.amount/1.2).toFixed(2)
        },
    },
    validations: {
        firstname:{
            required
        },
        lastname:{
            required
        },
        email:{
            required,
            email
        },
        tel:{
            required
        },
        toName:{
            required
        },
        message:{
            required
        }
    },
    methods: {
        handleSubmit() {
            this.$v.$touch()
            if(this.$v.$anyError){
                return
            }
            this.phone ? this.error() : this.postVoucher()
        },
        async postVoucher(){
            await this.getMyInfos()
            this.loadershow=true
            axios.post(`myvoucher`, {
                sender: {
                    ulid:this.ulid_user
                },
                receiver: {
                    firstname:this.firstname,
                    lastname:this.lastname,
                    email:this.email,
                    tel:this.tel
                },
                message:this.message,
                price:Number(this.amount),
                "minprice":20,
                "maxprice":300,
                name:"carte cadeau",
                tags:["carte", "cadeau", "bon d'achat"],
                description:"cadeau d'un montant de" +' '+ Number(this.amount) +' €'
            })
            .then(resVouchers => {
                console.log(resVouchers)
                this.ulidVoucher=resVouchers.data.ulid
                this.postMyCart()
            })
            .catch(errVouchers => {
                console.error({errVouchers})
                let error = errVouchers.response
                error.status=="404" && error.data=="Sender (User) not found!" || error.status=="401" || error.status=="403" ?alert('Vous devez vous connecter avant d\'envoyer cette carte cadeau'):error.status=="404" && error.data!="Sender (User) not found!"
                ?alert('Vous avez rencontrer une erreur'): error.status=="500"?alert('Erreur de données'):0
                this.$router.push({
                    name:"login"
                })
            })
        },
        postMyCart () {
            let body = {
                    deliveryinfos:{
                        name:this.firstname+' '+this.lastname,
                        street1: "64 AV. Saint-Exupery, 81600 Gaillac",
                        // street2: "null",
                        city: "Toulouse",
                        zip: "any",
                        state:"Av. Saint-Exupéry",
                        country:"France"
                    },
                    shippingprovider:"Sur place",
                    shippingprice:0,
                    volume:0,
                    weight:0,
                    price:this.totalpice,
                    status:"Préparation",
                    datedelivery:this.dateString(),
                    dateorder:this.dateString()
            }
            axios.post('myorders',body)
                .then(resmyroder => {
                    this.mypurchases(resmyroder.data.order)
                    this.validateMyorder(resmyroder.data.order)
                    this.loadershow = false
                    localStorage.removeItem('services')
                    this.$swal({
                        title:'Carte cadeau effectuée!',
                        text:'Vous allez être redirigé sur la page de paiement',
                        confirmButtonColor: '#0b0b0b',
                        confirmButtonText: 'Fermer'
                    })
                    this.loadershow=true
                    this.cart = []
                })
                .catch(errmyorder => {
                    let error = errmyorder.response
                    error.status=="404" && error.data=="Sender (User) not found!" || error.status=="401" || error.status=="403" ?alert('Vous devez vous connecter avant d\'envoyer cette carte cadeau'):error.status=="404" && error.data!="Sender (User) not found!"
                    ?alert('Vous avez rencontrer une erreur'): error.status=="500"?alert('Erreur de données'):0
                    this.$router.push({
                        name:"login"
                    })
                })
        },
        mypurchases(myorder){
            //get order ulid to the post my order response
            //post mypurchases
            axios.post(`mypurchases`,{
                order:myorder,
                voucher:this.ulidVoucher,
                datestart:this.dateString(),
                dateend:this.dateString(),
                quantity:1
            },{
                headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token')
              }
            })
            .then(resMypurchases => {
                console.log(resMypurchases)
            })
            .catch(errMypurchases => console.log(errMypurchases))
        },
        // validate to stripe
        validateMyorder(ulid) {
            axios.put(`myorders/validate/${ulid}`,{
                status:"En cours",
                success_url:process.env.VUE_APP_SUCCESS_URL,
                cancel_url:process.env.VUE_APP_ERROR_URL,
                mode:"payment",
                shipping_provider:process.env.VUE_APP_ON_THE_SPOT,
                },
                {
                withCredentials: true
            })
            .then(resValidateMyorder => {
                window.location.href = resValidateMyorder.data.url;
                console.log('window location',resValidateMyorder.data.url)
                console.log('Un mail a été envoyé',resValidateMyorder)
            })
            .catch(errValidateMyorder => {
                alert('Erreur sur la validation du paiement')
                console.log(errValidateMyorder)
            })
        },
        //générateur de date + reformatage de la date en string
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        error() {
            alert("Vous êtes un robot")
        },
        getMyInfos(){
                this.loadershow = true
                axios.get('myinfos')
            .then(resGeter => {
                this.loadershow = false
                this.ulid_user = resGeter.data.ulid
            })
            .catch(errGet => {
                alert("Merci de bien vouloir vous connecter")
                this.$router.push({
                    name:'login'
                })
                this.loadershow = false
                console.log({errGet})

            })
        }
    },
    mounted(){
        this.getMyInfos()
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
    }
}
</script>
<style scoped>
#prestations img {
    width: 100%;
    overflow: auto;
}
.phone {
    display: none;
}
</style>